import { inject } from "@angular/core";
import { CanDeactivateFn } from "@angular/router";
import { BlockNavigationIfChange } from "@sod/core/models/BlockNavigationIfChange";
import { Observable, take } from "rxjs";
import { Store } from '@ngxs/store';
import { STATE_TOKEN } from '@sod/store/app.state.model';

export const changedGuard: CanDeactivateFn<BlockNavigationIfChange> = (component, currentRoute, currentState, nextState) => {
  if (nextState.url.includes("login")) return true;
  if (nextState.url.includes("logout")) return true;
  const state = inject(Store).selectSnapshot(STATE_TOKEN);
  if (!component?.canDestroy) return true;
  return new Observable<boolean>((observer) => {
    component.canDestroy.pipe(take(1)).subscribe((hasChanges: boolean) => {
      const result = hasChanges && state.token ? confirm('You have changes which will be lost') : true;
      observer.next(result);
      observer.complete();
    });
  });
};

