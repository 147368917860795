import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Principal } from '@sod/core/models';
import { STATE_TOKEN } from '@sod/store/app.state.model';


export const authGuard: CanActivateFn = (route, state) => {
  const store = inject(Store);
  const router = inject(Router);
  const stateToken = store.selectSnapshot(STATE_TOKEN);
  if (!stateToken || !stateToken.token) {
    router.navigate(["logout"]);
  }
  const token = new Principal(stateToken.token);
  if(!token.TokenValid())
    return router.navigate(["logout"]);

  return true;
};



export const roleGuard = (requiredRoles: string[]): CanActivateFn => {
  return (route: ActivatedRouteSnapshot) => {
    const store = inject(Store);
    const stateToken = store.selectSnapshot(STATE_TOKEN);
    if (!stateToken) return false;
    const principal = new Principal(stateToken.token);
    const roles = principal.Role();
    const hasRole = roles && requiredRoles.some((role) => roles.includes(role));

    return hasRole;
  }
};
